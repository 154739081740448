import { makeAutoObservable } from "mobx";
import {IXProduct} from "../models/Product";
import {StorageKeys} from "../utils/defaults";

export default class LoyaltyStoreX {

  storeList: IXProduct[] = []
  storeSubsList: IXProduct[] = []
  bestList: IXProduct[] = []
  bestListIx: number = 0
  bestListLoading: boolean = false

  totalSale: number = 40

  storiesTab: string = "inview"

  bonuses: number = 0
  bonusCard: any | null = null
  bonusHistory: any[] = []
  chargedVisits: any[] = []

  isAccountLoaded: boolean = false

  visits: any[] = []

  loadingTimes: number = 1

  lastVisitDate: string = ""

  monthsStats: any | null = null

  isHelloVisible: boolean = false

  isPaySuccessVisible: boolean = false
  isPayFailVisible: boolean = false
  isBranchFindVisible: boolean = false
  isSupportVisible: boolean = false
  isPromoVisible: boolean = false
  isRulesVisible: boolean = false
  isExcludedVisible: boolean = false

  searchBranch: string = ""

  selectedOrder: any | null = null

  paySum: string = ""

  pageIndex: number = 0

  isLMVisible: boolean = true
  isSubMaxVisible: boolean = false
  isSubMinVisible: boolean = false

  selectedBranch: any | null = null

  historySheatVisible: boolean = false

  wishesList: any[] = []
  selectedWish: any | null = null
  isWishesVisible: boolean = false
  isDeleteWish: boolean = false
  wishText: string = ""

  favorites: any[] = []

  favsVisible: boolean = false

  refUser: any | null = null

  new_visits: any[] = []
  isNewVstsVisible: boolean = false

  isAccepted: boolean = false

  selectedProduct: any | null = null
  isProductVisible: boolean = false
  isOrdersVisible: boolean = false
  isReferalVisible: boolean = false
  isSubscribeVisible: boolean = false
  isAccountVisible: boolean = false
  isHistoryVisible: boolean = false
  isBonusHistoryVisible: boolean = false

  mainVisible: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setMainVisible(val: boolean) {
    this.mainVisible = val
  }

  setIsBonusHistoryVisible(val: boolean) {
    this.isBonusHistoryVisible = val
  }

  setIsHistoryVisible(val: boolean) {
    this.isHistoryVisible = val
  }

  setIsAccountVisible(val: boolean) {
    this.isAccountVisible = val
  }

  setIsOrdersVisible(val: boolean) {
    this.isOrdersVisible = val
  }

  setIsReferalVisible(val: boolean) {
    this.isReferalVisible = val
  }

  setIsSubscribeVisible(val: boolean) {
    this.isSubscribeVisible = val
  }

  setIsProductVisible(val: boolean) {
    this.isProductVisible = val
  }

  setSelectedProduct(val: any) {
    this.selectedProduct = val
  }

  setIsAccepted(value: boolean) {
    this.isAccepted = value
  }

  setNewVisits(val: any) {
    this.new_visits = val
  }

  setIsNewVstsVisible(val: boolean) {
    this.isNewVstsVisible = val
  }

  setRefUser(user: any) {
    this.refUser = user
  }

  setFavsVisible(val: boolean) {
    this.favsVisible = val
  }

  setFavs(val: any) {
    this.favorites = val
  }

  setWishText(val: string) {
    this.wishText = val
  }

  setWishesList(val: any[]) {
    this.wishesList = val
  }

  setSelectedWish(val: any) {
    this.selectedWish = val
  }

  setIsWishesVisible(val: boolean) {
    this.isWishesVisible = val
  }

  setIsDeleteWish(val: boolean) {
    this.isDeleteWish = val
  }

  setHistorySheatVisible(val: boolean) {
    this.historySheatVisible = val
  }

  setSelectedBranch(branch: any) {
    this.selectedBranch = branch
  }

  setIsExcludedVisible(val: boolean) {
    this.isExcludedVisible = val
  }

  setIsRulesVisible(val: boolean) {
    this.isRulesVisible = val
  }

  setIsSubMaxVisible(val: boolean) {
    this.isSubMaxVisible = val
  }

  setIsSubMinVisible(val: boolean) {
    this.isSubMinVisible = val
  }

  setIsHelloVisible(val: boolean) {
    this.isHelloVisible = val
  }

  setIsLMVisible(val: boolean) {
    this.isLMVisible = val
  }

  setPageIndex(val: number) {
    this.pageIndex = val
  }

  setPaySum(val: string) {
    this.paySum = val
  }

  setIsPromoVisible(val: boolean) {
    this.isPromoVisible = val
  }

  setIsSupportVisible(val: boolean) {
    this.isSupportVisible = val
  }

  setSelectedOrder(val: any) {
    this.selectedOrder = val
  }
 
  setSearchBranch(val: string) {
    this.searchBranch = val
  }

  setIsBranchFindVisible(val: boolean) {
    this.isBranchFindVisible = val
  }

  setIsPaySuccessVisible(val: boolean) {
    this.isPaySuccessVisible = val
  }
  
  setIsPayFailVisible(val: boolean) {
    this.isPayFailVisible = val
  }

  setMonthsStats(val: any) {
    this.monthsStats = val
    localStorage.setItem(StorageKeys.MONTH_STATS, JSON.stringify(val))
  }

  setLastVisitDate(val: string) {
    this.lastVisitDate = val
  }

  setLoadingTimes(times: number) {
    this.loadingTimes = times
  }

  setVisits(val: any[]) {
    this.visits = val
  }

  setIsAccountLoaded(val: boolean) {
    this.isAccountLoaded = val
  }

  setBonusCard(val: any) {
    this.bonusCard = val
  }

  setBonuses(val: number) {
    this.bonuses = val
  }

  setBonusHistory(val: any[]) {
    this.bonusHistory = val
  }

  setChargedVisits(val: any[]) {
    this.chargedVisits = val
  }

  setStoriesTab(val: string) {
    this.storiesTab = val
  }

  setStoreList(val: IXProduct[]) {
    this.storeList = val
  }

  setStoreSubsList(val: IXProduct[]) {
    this.storeSubsList = val
  }

  setBestList(val: IXProduct[]) {
    this.bestList = val
  }

  setBestListIx(val: number) {
    this.bestListIx = val
  }

  setBestListLoading(val: boolean) {
    this.bestListLoading = val
  }

}